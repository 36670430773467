

<template>
  <div class="dashboard_wrap">
    <div class="dashboard_top" ref="dashboardTop">
      <div class="card card1">
        <img class="card_left" src="@/assets/images/dashboard/total.png" alt="">
        <div class="card_right">
          <div class="title">应用总数</div>
          <div class="number">{{appTotalInfo.total}}</div>
          <div class="stats">
            <div class="stats_item">
              <span class="label">本周上新</span>
              <span class="triangle"></span>
              <span class="value">{{appTotalInfo.weekUp}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card card2" v-for="item in topItems" :key="item.title"
        :style="topItems.length > 3 ? { 'margin-left': '20px','min-width':'400px' } : {}">
        <img class="card_left" :src="require(`@/assets/images/dashboard/${item.appType}.png`)" alt="">
        <div class="card_right">
          <div class="title">{{item.appType}}数</div>
          <div class="number">{{item.total}}</div>
          <div class="stats">
            <div class="stats_item">
              <span class="label">已上线</span>
              <span class="green round"></span>
              <span class="value">{{item.up}}</span>
            </div>
            <div class="line"></div>
            <div class="stats_item">
              <span class="label">待审核</span>
              <span class="orange round"></span>
              <span class="value">{{item.unaudited}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard_bot">
      <div class="dashboard_visitCount">
        <div class="stats-card-container">
          <div class="stats-card" v-for="stat in visitorList" :key="stat.title"
            :class="{'visitor-number-flag': stat.label === '访客数'}">
            <div class="card-class">{{ stat.class }}-</div>
            <div class="card-title">{{ stat.label }}</div>
            <div class="card-value">{{ stat.value }}</div>
          </div>
        </div>
        <div class="echart_container">
          <div class="searchParams">
            <el-date-picker v-model="timeRanger" @change='changeTime' type="daterange" value-format='yyyy-MM-dd'
              range-separator="-" start-placeholder="开始" end-placeholder="结束">
            </el-date-picker>
            <el-button type="primary" class="query_btn" @click="queryPvEchart">查询</el-button>
            <el-button type="primary" class="reset_btn" @click="reset">重置</el-button>
          </div>
          <visit-count-chart :chartData="chartData"></visit-count-chart>
        </div>
      </div>
      <div class="dashboard_leaderboard">
        <div class="title">
          <span class="line"></span>
          <span>应用访问量排名TOP10</span>
        </div>
        <el-table :data="rankingList" style="width: 100%" :header-cell-style="{background:'#f5f5f5',color:'#A8A8A8'}">
          <el-table-column prop="" label="排名" width="50">
            <template slot-scope="scope">
              <div class="ranking" :class="getClassByRank(scope.$index + 1)">{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="appName" label="应用名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="total" label="访问量（PV)" align="right">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import visitCountChart from './visitCountChart.vue'
import api from '@/api/api'
import dayjs from 'dayjs';
export default {
  components: {
    visitCountChart,
  },
  data () {
    return {
      appTotalInfo: {},
      topItems: [
        { appType: '大模型算法', total: '0', up: '0', unaudited: '0' },
        { appType: 'AI应用', total: '0', up: '0', unaudited: '0' },
        { appType: '语音交互算法', total: '0', up: '0', unaudited: '0' }],
      visitorList: [
        { class: '今日', label: '访问量', value: '', type: 'dayNum' },
        { class: '今日', label: '访客数', value: '', type: 'dayCustom' },
        { class: '总', label: '访问量', value: '', type: 'allNum' },
        { class: '总', label: '访客数', value: '', type: 'allCustom' },
      ],
      rankingList: [],
      chartData: [],
      timeRanger: [],

    }
  },

  created () {
    this.initTimeRanger()
    this.$nextTick(() => {
      this.queryData()
      this.queryPvEchart();
    })

  },
  methods: {

    getClassByRank (rank) {
      if (rank === 1) {
        return 'first-place';
      } else if (rank === 2) {
        return 'second-place';
      } else if (rank === 3) {
        return 'third-place';
      } else {
        return '';
      }
    },
    queryData () {
      this.queryAppTopList()
      this.queryVisitCountList();
      this.queryAppSummaryList()
    },
    //应用访问量排名TOP10
    queryAppTopList () {
      api.homeAppTopList().then(res => {
        if (res.code == 200) {
          this.rankingList = res.data;
        }
      })
    },
    //访问量总计
    queryVisitCountList () {
      api.getSummaryList().then(res => {
        if (res.code == 200) {
          let data = res.data;
          if (data) {
            this.visitorList = this.visitorList.map(item => ({
              ...item,
              value: data[item.type]
            }));
          }
        }
      })
    },
    //应用场景算法平台总数
    queryAppSummaryList () {
      api.getAppSummaryList().then(res => {
        if (res.code == 200) {
          if (res.data.appHomes.length) {
            this.topItems = res.data.appHomes
          }
          this.appTotalInfo = {
            total: res.data.total,
            weekUp: res.data.weekUp
          }

        }
      })
    },
    changeTime (val) {
      this.timeRanger = val;
    },
    queryPvEchart () {
      if (!this.timeRanger) return
      let parmas = {}
      parmas.startTime = this.timeRanger[0]
      parmas.endTime = this.timeRanger[1]
      api.getPvList(parmas).then(res => {
        if (res.code == 200) {
          this.chartData = res.data;
          console.log(this.chartData, 'this.chartDatathis.chartData')
        }
      })
    },
    reset () {
      this.initTimeRanger();
      this.queryPvEchart()
    },
    initTimeRanger () {
      const end = dayjs().hour(0).minute(0).second(0);
      const start = dayjs().subtract(7, 'day').hour(0).minute(0).second(0);
      this.timeRanger = [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')];
    }
  },
  mounted () {

  },



}
</script>

<style lang="scss" scoped>
::v-deep.dashboard_wrap {
  .dashboard_top {
    display: flex;
    /* justify-content: space-between; */
    overflow: hidden;
    padding-bottom: 5px;
    overflow-x: auto;
    /* 自定义Webkit浏览器的滚动条样式 */

    .card {
      box-sizing: border-box;
      min-width: 396px !important;
      height: 140px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      margin-left: 18px;

      .card_left {
        width: 104px;
        height: 120px;
      }
      .card_right {
        flex: 1;
        box-sizing: border-box;
        padding: 14px;
        padding-right: 0px;
        margin-left: 14px;
        .title {
          color: #333;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        .number {
          color: #333;
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 22px;
        }

        .stats {
          display: flex;

          align-items: center;
          font-size: 14px;
          .line {
            width: 0.1px;
            height: 14px;
            border: 0.5px solid #cfd3da;
            background: #cfd3da;
            margin-right: 15px;
          }
          .stats_item {
            margin-right: 20px;
            display: flex;
            align-items: center;
            .label {
              color: #a8a8a8;
            }
            .value {
              color: #424242;
            }
          }
          .round {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin: 0 5px 0 8px;
          }
          .green {
            background: greenyellow;
          }
          .orange {
            background: #efac1d;
          }
        }
      }
    }
    .card:first-child {
      margin: 0;
    }
    .card1 {
      .card_left {
        width: 160px;
        height: 140px;
      }
      .card_right {
        padding: 24px;
        .title {
          color: #2874f7;
          font-weight: 700;
        }

        .triangle {
          width: 0;
          height: 0;
          margin: 0 8px 0 24px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #28e1b3; /* 高度为边长的根号3/2 */
        }
      }
    }
    .card2 {
      box-sizing: border-box;
      padding: 12px 18px;
    }
  }
  .dashboard_bot {
    height: 646px;
    margin-top: 16px;

    display: flex;
    justify-content: space-between;
    .dashboard_visitCount {
      box-sizing: border-box;
      flex: 1;
      border-radius: 4px;
      background: #ffffff;
      padding: 16px;
      margin-right: 20px;
      .stats-card-container {
        display: flex;
        justify-content: space-between;
        .stats-card {
          width: 285px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 700;
          background: url("~@/assets/images/dashboard/PV.png");
          background-size: 100% 100%;
          .card-class {
            color: #2774f7;
          }
          .card-title {
            color: #333;
            margin: 0 23px 0 5px;
          }
          .card-value {
            font-size: 24px;
            color: #2774f7;
          }
        }
        .visitor-number-flag {
          background: url("~@/assets/images/dashboard/UV.png");
          background-size: 100% 100%;
          .card-class,
          .card-value {
            color: #efac1d;
          }
        }
      }
      .echart_container {
        height: calc(100% - 94px);

        background: #fff;
        margin-top: 20px;

        .searchParams {
          .el-date-editor {
            width: 285px !important;
            height: 36px;
            background: #ffffff;
            border: 1px solid #dbdbdb;
            border-radius: 2px;
          }
          .el-button {
            width: 80px;
            height: 36px;
            border-radius: 2px;
            margin-left: 16px;
          }
          .query_btn {
            background: #2874f7 !important;
          }
          .reset_btn {
            background: #ffffff !important;
            border: 1px solid #2874f7 !important;
            border-radius: 2px;
            color: #2874f7;
          }
        }
      }
    }
    .dashboard_leaderboard {
      background: #ffffff;
      border-radius: 2px;
      text-align: center;
      box-sizing: border-box;
      width: 396px;
      padding: 16px;
      .title {
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        text-align: left;
        color: #424242;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #2874f7;
          margin-right: 6px;
        }
      }
      .ranking {
        width: 18px;
        height: 18px;
        background: #ffffff;
        border: 1px solid #a8a8a8;
        border-radius: 50%;
        font-size: 12px;
        color: #a8a8a8;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .first-place {
        width: 20px;
        height: 20px;
        background: #2874f7;
        color: #fff;
        border: none;
      }

      .second-place {
        background: #2874f7;
        color: #fff;
        border: none;
      }

      .third-place {
        border: 1px solid #2874f7;
        color: #2874f7;
      }
      background: #fff;
      .el-table {
        border: none;
        font-size: 14px;
        color: #333333;
        &::before {
          /* content: ""; */
          height: 0px;
        }
        td,
        th.is-leaf {
          /* border: none !important; */
        }
      }
    }
  }
}
::-webkit-scrollbar {
  margin-top: 20px;
  height: 6px; /* 调整滚动条的高度 */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道的背景色 */
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #2a8cf5; /* 滚动条滑块的背景色 */
}

::-webkit-scrollbar-thumb:hover {
  background: #2a8cf5; /* 滚动条滑块鼠标悬停时的背景色 */
}
</style>