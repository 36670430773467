<!--
 * @Descripttion: 
 * @Author: yyh
 * @Date: 2024-03-11 16:22:43
 * @LastEditors: yyh
 * @LastEditTime: 2024-03-11 16:27:57
-->

<template>
  <div class="base-echart" ref="echartDivRef"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    option: {
      type: Object,
      default: () => { },
    },
  },

  data () {
    return {
      echartInstance: null,
    };
  },
  watch: {
    option: {
      handler () {
        this.initEchart();
      },
      deep: true,
    },
  },
  methods: {
    initEchart () {
      this.echartInstance = echarts.init(this.$refs.echartDivRef);
      this.echartInstance.setOption(this.option);
    },
  },
  mounted () {
    this.initEchart();
    window.onresize = () => {
      this.echartInstance.resize();
    };
  },
};
</script>

<style lang="scss" scoped>
.base-echart {
  height: 100%;
  width: 100%;
}
</style>
